<template>
  <!-- pagination -->
  <b-pagination
    v-model="currentPage"
    :total-rows="totalRows"
    :per-page="perPage"
    first-number
    last-number
    ellipsis-text="ººº"
    class="pagination-danger mt-2"
  >
    <template #prev-text>
      <feather-icon
        size="18"
        icon="ChevronLeftIcon"
      />
    </template>
    <template #next-text>
      <feather-icon
        size="18"
        icon="ChevronRightIcon"
      />
    </template>
  </b-pagination>
</template>

<script>
import { BPagination } from 'bootstrap-vue'

export default {
  components: {
    BPagination,
  },
  props: {
    totalRows: {
      type: Number,
      default: 100,
    },
    perPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentPage: 1,
    }
  },
}
</script>

<style lang="scss" scoped>

  .pagination {
    ::v-deep {
      .page-item.active {
        background-color: transparent;
        border-radius: 0;
      }
      .page-item.active .page-link {
        background: #ea5455 !important;
      }
      .page-link {
        background-color: transparent !important;
        color: #6f9ca8;
        font-weight: 200;
      }
      .page-link:hover {
        background-color: transparent !important;
        color: #6f9ca8;
      }
    }
  }

</style>
