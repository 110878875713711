import useJwt from '@/auth/aiops/useJwt'
import utilsAiops from '@/@core/utils/aiops/utils'

const { formatDesc, formatDate } = utilsAiops()

export default {
  namespaced: true,
  state: {
    items: [],
    tickets: [],
    modelName: '',
    modelEnabled: [],
    categoriesTree: [],
  },
  getters: {
    getItems(state) {
      return state.items
    },
  },
  mutations: {
    setItems(state, payload) {
      state.items = payload
    },
    setTickets(state, payload) {
      state.tickets = payload
    },
    setModelName(state, payload) {
      state.modelName = payload
    },
    setModelEnabled(state, payload) {
      state.modelEnabled = payload
    },
    setCategoriesTree(state, payload) {
      state.categoriesTree = payload
    },
  },
  actions: {
    dataTable({ commit }, _data) {
      return new Promise((resolve, reject) => {
        useJwt.getTickets(_data)
          .then(response => {
            const { data } = response
            const { tickets, total } = data
            const items = []
            if (total) {
              tickets.forEach(item => {
                const dataTable = {
                  isActive: false,
                  idTicket: item.idTicket,
                  ticketSourceNumber: item.ticketSourceNumber,
                  summary: formatDesc(item.summary),
                  area: item.area,
                  categoryLevel2: item.categoryLevel2,
                  teisStatus: item.teisStatus,
                  status: item.status,
                  priority: item.priority,
                  createdDate: formatDate(item.createdDate),
                  modifiedDate: formatDate(item.modifiedDate),
                }
                items.push(dataTable)
              })
              commit('setItems', items)
              commit('setTickets', tickets)
              resolve({ items, tickets, total })
            } else {
              resolve({ items, tickets })
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    dataModelTree({ commit }) {
      return new Promise((resolve, reject) => {
        useJwt.getModelTree()
          .then(response => {
            const { data } = response
            const tickets = data
            // let modelName = null
            let modelEnabled = []
            modelEnabled = tickets.find(item => item.enabled)
            commit('setModelEnabled', modelEnabled)
            resolve(modelEnabled)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    dataModelTreeToFilter({ commit }) {
      return new Promise((resolve, reject) => {
        useJwt.getModelTree()
          .then(response => {
            const { data } = response
            const tickets = data
            // let modelName = null
            let modelEnabled = []
            modelEnabled = tickets
            commit('setModelEnabled', modelEnabled)
            resolve(modelEnabled)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getPredictionModel({ commit }, _modelBody) {
      return new Promise((resolve, reject) => {
        useJwt.getPredictionModel(_modelBody)
          .then(response => {
            const { data } = response
            const predictedData = data
            commit('setModelName', _modelBody.modelName)
            resolve(predictedData)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    dataCategoriesTree({ commit }) {
      return new Promise((resolve, reject) => {
        useJwt.getCategoriesTree()
          .then(response => {
            const { data } = response
            commit('setCategoriesTree', data)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
