<template>
  <div
    class="mt-2
    col-12"
    align-h="center"
  >
    <h3
      class="aiops_table_title"
    >
      Open
    </h3>
    <b-table
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      thead-class="colorHead"
      tbody-class="colorBody"
    >
      <template #cell(ticket_id)="data">
        <a>{{ data.value }}</a>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
      <!-- pagination -->
      <slot
        name="pagination"
      />
    </b-card-body>
  </div>
</template>

<script>
import {
  BTable,
  BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BCardBody,
  },
  props: {
    perPage: {
      type: Number,
      default: () => 0,
    },
    totalRows: {
      type: Number,
      default: () => 0,
    },
    currentPage: {
      type: Number,
      default: () => 0,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>

.aiops_table_title {
  color: rgba(0, 170, 155, 1);
  font-family: "Open Sans", Helvetica, Arial, serif;
  font-style: normal;
  font-weight: 200;
  word-wrap: break-word;
}

::v-deep {
  .colorHead {
    background-color: #f3f2f7;
    color: #005573;
    font-family: "Open Sans", Helvetica, Arial, serif;
  }

  .colorBody {
    background-color: #f3f2f7;
    color: #6f9ca8;
    font-family: "Open Sans", Helvetica, Arial, serif;
  }

  b-pagination .page-item .active .page-link {
    border-radius: 5rem;
    background-color: #12a71e !important;
  }

  .table tbody tr {
    height: 55px !important;
  }
}

</style>
