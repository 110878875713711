<template>
  <div
    class="mt-2 col-12"
    align-h="center"
  >
    <b-row
      class="mintui__background-f6fafb ml-0 mr-0"
    >
      <b-row
        class="col-md-12"
      >
        <h4
          v-b-toggle="'collapse-filter'"
          class="aiops_mineral_green_heading4 padding-top-24 padding-left-32"
          style="text-decoration: none;"
          @click="setFilterClose"
        >
          FILTERS
          <b-img
            :class="arrowFilter"
          />
        </h4>
      </b-row>
      <b-row
        class="col-md-12 padding-bottom-24"
      >
        <b-collapse
          id="'collapse-filter'"
          v-model="filterClose"
          class="col-md-12 mt-2"
        >
          <validation-observer
            ref="filteringModel"
          >
            <b-form>
              <b-row
                v-for="(filter, index) in filtersTable"
                :key="index"
                class="col-md-12 padding-bottom-24"
              >
                <b-row
                  class="col-md-12 pl-0"
                >
                  <b-col
                    class="col-md-3 pl-0"
                  >
                    <b-form-group
                      class="aiops_label_20 padding-left-32 mb-0"
                      label-for="issue"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="issue"
                        rules="required"
                      >
                        <b-form-select
                          id="issue"
                          v-model="filter.field"
                          v-b-tooltip.hover.v-success.top
                          title="Choose option"
                          :options="filterFields"
                          @change="setValueNull(index)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="col-md-3 pl-0"
                  >
                    <b-form-group
                      class="aiops_label_20"
                      label-for="condition"
                    >
                      <validation-provider
                        v-if="filter.field && (filter.field === 'area' || filter.field === 'categoryLevel2' || filter.field === 'teisStatus' || filter.field === 'assignedTo' || filter.field === 'summary')"
                        #default="{ errors }"
                        name="condition"
                        rules="required"
                      >
                        <b-form-select
                          id="condition"
                          v-model="filter.condition"
                          v-b-tooltip.hover.v-success.top
                          title="Choose operator"
                          :options="filterConditions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <validation-provider
                        v-if="filter.field && (filter.field === 'createdDate' || filter.field === 'modifiedDate')"
                        #default="{ errors }"
                        name="condition"
                        rules="required"
                      >
                        <b-form-select
                          id="condition"
                          v-model="filter.condition"
                          v-b-tooltip.hover.v-success.top
                          title="Choose operator"
                          :options="filterConditionsDate"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <validation-provider
                        v-if="filter.field && (filter.field === 'ticketSourceNumber' || filter.field === 'description')"
                        #default="{ errors }"
                        name="condition"
                        rules="required"
                      >
                        <b-form-select
                          id="condition"
                          v-model="filter.condition"
                          v-b-tooltip.hover.v-success.top
                          title="Choose operator"
                          :options="filterConditionsStrings"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <b-form-input
                        v-if="!filter.field"
                        id="condition"
                        v-model="filter.condition"
                        autocomplete="off"
                        disabled
                        placeholder="-- Oper --"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="col-md-3 pl-0"
                  >
                    <b-form-group
                      class="aiops_label_20"
                      label-for="value"
                    >
                      <validation-provider
                        v-if="filter.field && (filter.field === 'ticketSourceNumber' || ( filter.field === 'summary' && (filter.condition !== '=NULL' && filter.condition !== '!=NULL')) || filter.field === 'description' || ( (filter.field === 'area' || filter.field === 'categoryLevel2') && (filter.condition === '_LIKE_')))"
                        #default="{ errors }"
                        name="value"
                        rules="required"
                      >
                        <b-form-input
                          id="value"
                          v-model="filter.fieldValue"
                          v-b-tooltip.hover.v-success.top
                          autocomplete="off"
                          title="Choose option"
                          placeholder="-- Value --"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <validation-provider
                        v-if="filter.field && (filter.field === 'createdDate' || filter.field === 'modifiedDate')"
                        #default="{ errors }"
                        name="value"
                        rules="required"
                      >
                        <b-form-input
                          id="value"
                          v-model="filter.fieldValue"
                          v-b-tooltip.hover.v-success.top
                          autocomplete="off"
                          title="Choose option"
                          type="date"
                          placeholder="-- Value --"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <validation-provider
                        v-if="filter.field && filter.field === 'area' && (filter.condition !== '=NULL' && filter.condition !== '!=NULL' && filter.condition !== '_LIKE_')"
                        #default="{ errors }"
                        name="value"
                        rules="required"
                      >
                        <b-form-select
                          id="value"
                          v-model="filter.fieldValue"
                          v-b-tooltip.hover.v-success.top
                          title="Choose option"
                          :options="selectAreaFilter"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <validation-provider
                        v-if="filter.field && filter.field === 'categoryLevel2' && (filter.condition !== '=NULL' && filter.condition !== '!=NULL' && filter.condition !== '_LIKE_')"
                        #default="{ errors }"
                        name="value"
                        rules="required"
                      >
                        <b-form-select
                          id="value"
                          v-model="filter.fieldValue"
                          v-b-tooltip.hover.v-success.top
                          title="Choose option"
                          :options="selectCatL2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <validation-provider
                        v-if="filter.field && filter.field === 'teisStatus' && (filter.condition !== '=NULL' && filter.condition !== '!=NULL')"
                        #default="{ errors }"
                        name="value"
                        rules="required"
                      >
                        <b-form-select
                          id="value"
                          v-model="filter.fieldValue"
                          v-b-tooltip.hover.v-success.top
                          title="Choose option"
                          :options="fieldsTeisStatus"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <validation-provider
                        v-if="filter.field && filter.field === 'assignedTo' && (filter.condition !== '=NULL' && filter.condition !== '!=NULL')"
                        #default="{ errors }"
                        name="value"
                        rules="required"
                      >
                        <b-form-select
                          id="value"
                          v-model="filter.fieldValue"
                          v-b-tooltip.hover.v-success.top
                          title="Choose option"
                          :options="optionsGroup"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <b-form-input
                        v-if="!filter.field"
                        id="value"
                        v-model="filter.fieldValue"
                        autocomplete="off"
                        disabled
                        placeholder="-- Value --"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    class="col-md-1 text-right"
                  >
                    <b-button-group
                      class="ibop-gothic-button_a7ccce"
                    >
                      <b-button
                        v-if="filter.field"
                        v-b-tooltip.hover.v-success.top
                        class="ibop-gothic-button_a7ccce"
                        title="Add AND condition"
                        @click="addAndFilter"
                      >
                        <span
                          class="align-middle aiops_label_14_3e7282"
                        >
                          AND
                        </span>
                      </b-button>
                      <b-button
                        v-if="filter.field"
                        v-b-tooltip.hover.v-success.top
                        class="ibop-gothic-button_a7ccce"
                        title="Add OR condition"
                        @click="addOrFilter(index)"
                      >
                        <span
                          class="align-middle aiops_label_14_3e7282"
                        >
                          OR
                        </span>
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.v-warning.top
                        class="ibop-gothic-button_a7ccce"
                        title="Delete"
                        @click="deleteAndFilter(index)"
                      >
                        <span
                          class="align-middle aiops_label_14_3e7282"
                        >
                          X
                        </span>
                      </b-button>
                    </b-button-group>
                  </b-col>
                </b-row>
                <b-row
                  v-if="filter.children.length > 0"
                  class="col-md-12 pl-0"
                >
                  <b-row
                    v-for="(filterChildren, i) in filter.children"
                    :key="i"
                    class="col-md-12 pl-0"
                  >
                    <b-col
                      class="col-md-1 text-right"
                    >
                      <span class="align-middle aiops_label_14_3e7282">Or</span>
                    </b-col>
                    <b-col
                      class="col-md-3 pl-0"
                    >
                      <b-form-group
                        class="aiops_label_20 padding-left-32 mb-0"
                        label-for="issue"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="issue"
                          rules="required"
                        >
                          <b-form-select
                            id="issue"
                            v-model="filterChildren.field"
                            v-b-tooltip.hover.v-success.top
                            title="Choose option"
                            :options="filterFields"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      class="col-md-3 pl-0"
                    >
                      <b-form-group
                        class="aiops_label_20"
                        label-for="condition"
                      >
                        <validation-provider
                          v-if="filterChildren.field && (filterChildren.field === 'area' || filterChildren.field === 'categoryLevel2' || filterChildren.field === 'teisStatus' || filterChildren.field === 'assignedTo' || filterChildren.field === 'summary')"
                          #default="{ errors }"
                          name="condition"
                          rules="required"
                        >
                          <b-form-select
                            id="condition"
                            v-model="filterChildren.condition"
                            v-b-tooltip.hover.v-success.top
                            title="Choose operator"
                            :options="filterConditions"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider
                          v-if="filterChildren.field && (filterChildren.field === 'createdDate' || filterChildren.field === 'modifiedDate')"
                          #default="{ errors }"
                          name="condition"
                          rules="required"
                        >
                          <b-form-select
                            id="condition"
                            v-model="filterChildren.condition"
                            v-b-tooltip.hover.v-success.top
                            title="Choose operator"
                            :options="filterConditionsDate"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider
                          v-if="filterChildren.field && (filterChildren.field === 'ticketSourceNumber' || filterChildren.field === 'description')"
                          #default="{ errors }"
                          name="condition"
                          rules="required"
                        >
                          <b-form-select
                            id="condition"
                            v-model="filterChildren.condition"
                            v-b-tooltip.hover.v-success.top
                            title="Choose operator"
                            :options="filterConditionsStrings"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-form-input
                          v-if="!filterChildren.field"
                          id="typeStatus"
                          v-model="filterChildren.condition"
                          autocomplete="off"
                          disabled
                          placeholder="-- Oper --"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      class="col-md-3 pl-0"
                    >
                      <b-form-group
                        class="aiops_label_20"
                        label-for="value"
                      >
                        <validation-provider
                          v-if="filterChildren.field && (filterChildren.field === 'ticketSourceNumber' || ( filterChildren.field === 'summary' && (filterChildren.condition !== '=NULL' && filterChildren.condition !== '!=NULL')) || filterChildren.field === 'description')"
                          #default="{ errors }"
                          name="value"
                          rules="required"
                        >
                          <b-form-input
                            id="value"
                            v-model="filterChildren.fieldValue"
                            v-b-tooltip.hover.v-success.top
                            autocomplete="off"
                            title="Choose option"
                            placeholder="-- Value --"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider
                          v-if="filterChildren.field && (filterChildren.field === 'createdDate' || filterChildren.field === 'modifiedDate')"
                          #default="{ errors }"
                          name="value"
                          rules="required"
                        >
                          <b-form-input
                            id="value"
                            v-model="filterChildren.fieldValue"
                            v-b-tooltip.hover.v-success.top
                            autocomplete="off"
                            title="Choose option"
                            type="date"
                            placeholder="-- Value --"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider
                          v-if="filterChildren.field && filterChildren.field === 'area' && (filterChildren.condition !== '=NULL' && filterChildren.condition !== '!=NULL')"
                          #default="{ errors }"
                          name="value"
                          rules="required"
                        >
                          <b-form-select
                            v-if="filterChildren.field && filterChildren.field === 'area' && (filterChildren.condition !== '=NULL' && filterChildren.condition !== '!=NULL')"
                            id="value"
                            v-model="filterChildren.fieldValue"
                            v-b-tooltip.hover.v-success.top
                            title="Choose option"
                            :options="selectAreaFilter"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider
                          v-if="filterChildren.field && filterChildren.field === 'categoryLevel2' && (filterChildren.condition !== '=NULL' && filterChildren.condition !== '!=NULL')"
                          #default="{ errors }"
                          name="value"
                          rules="required"
                        >
                          <b-form-select
                            id="value"
                            v-model="filterChildren.fieldValue"
                            v-b-tooltip.hover.v-success.top
                            title="Choose option"
                            :options="selectCatL2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider
                          v-if="filterChildren.field && filterChildren.field === 'teisStatus' && (filterChildren.condition !== '=NULL' && filterChildren.condition !== '!=NULL')"
                          #default="{ errors }"
                          name="value"
                          rules="required"
                        >
                          <b-form-select
                            id="value"
                            v-model="filterChildren.fieldValue"
                            v-b-tooltip.hover.v-success.top
                            title="Choose option"
                            :options="fieldsTeisStatus"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider
                          v-if="filterChildren.field && filterChildren.field === 'assignedTo' && (filterChildren.condition !== '=NULL' && filterChildren.condition !== '!=NULL')"
                          #default="{ errors }"
                          name="value"
                          rules="required"
                        >
                          <b-form-select
                            id="typeStatus"
                            v-model="filterChildren.fieldValue"
                            v-b-tooltip.hover.v-success.top
                            title="Choose option"
                            :options="optionsGroup"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-form-input
                          v-if="!filterChildren.field"
                          id="typeStatus"
                          v-model="filterChildren.fieldValue"
                          autocomplete="off"
                          disabled
                          placeholder="-- Value --"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      class="col-md-1 text-right"
                    >
                      <b-button-group>
                        <b-button
                          v-b-tooltip.hover.v-warning.top
                          class="ibop-gothic-button_a7ccce"
                          title="Delete"
                          @click="deleteOrFilter(index, i)"
                        >
                          <span class="align-middle aiops_label_14_3e7282">X</span>
                        </b-button>
                      </b-button-group>
                    </b-col>
                  </b-row>
                </b-row>
              </b-row>
              <b-row
                class="col-md-12 padding-bottom-24"
              >
                <b-col
                  :offset-md="8"
                  class="col-md-4 text-right"
                >
                  <b-button
                    class="ibop-salmon-pearl-button"
                    @click="clearAll"
                  >
                    <span>Clear all</span>
                  </b-button>
                  <b-button
                    class="ibop-gothic-button ml-1"
                    @click="filterTable(filtersTable)"
                  >
                    <b-img
                      class="mintui_search_dark"
                    />
                    <span class="pl-1 align-middle aiops_label_14_3e7282">Search</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-collapse>
      </b-row>
    </b-row>
    <b-row
      v-if="filterOK"
      class="margin-bottom-24 margin-top-48"
    >
      <b-col
        class="col-md-12"
      >
        <a
          class=" aiops_serviceSnow_filter__breadCrumb"
          @click="selectFilterFrom(null, true)"
        >
          <span class="aiops_paragraph_6f9ca8 padding-top-4">All</span>
        </a>
        <span
          v-for="(filterItem, i) in filterBreadCrumb"
          id="idcol"
          :key="i"
          class=" mintui-nav-position-column-secondary"
        >
          <span
            class="aiops_paragraph_6f9ca8 padding-left-4 padding-top-4"
          >></span>
          <a
            class=" aiops_serviceSnow_filter__breadCrumb"
            @click="selectFilterFrom(i)"
          >
            <span class="aiops_paragraph_6f9ca8 padding-left-4 padding-right-4 padding-top-4">{{ filterItem.field | filterValueLabels }}</span>
            <span class="aiops_paragraph_6f9ca8 padding-top-4">.{{ filterItem.condition | filterValueCondition }}.</span>
            <span class="aiops_paragraph_6f9ca8 padding-left-4 padding-top-4">{{ filterItem.fieldValue | filterDeleteUnderScore }}</span>
            <span
              v-if="filterItem.children.length > 0"
            >
              <span
                v-for="(filterItemOr, index) in filterItem.children"
                :key="index"
              >
                <span class="aiops_paragraph_6f9ca8 padding-left-4 padding-right-4 padding-top-4">Or</span>
                <span class="aiops_paragraph_6f9ca8 padding-top-4">{{ filterItemOr.field | filterValueLabels }}</span>
                <span class="aiops_paragraph_6f9ca8 padding-top-4">.{{ filterItemOr.condition | filterValueCondition }}.</span>
                <span class="aiops_paragraph_6f9ca8 padding-left-4 padding-top-4">{{ filterItemOr.fieldValue | filterDeleteUnderScore }}</span>
              </span>
            </span>
          </a>
        </span>
      </b-col>
    </b-row>
    <b-row
      class="margin-bottom-24 margin-top-48"
    >
      <b-col
        class="col-md-1"
      >
        <h3
          class="aiops_mineral_green_heading2"
        >
          Tickets
        </h3>
      </b-col>
      <b-col
        :offset-md="9"
        class="col-md-2 text-right"
      >
        <b-button
          class="ibop-gothic-button"
          @click="dataTable(currentPage)"
        >
          <b-img
            class="mintui_icons8_update_left_rotation"
          />
        </b-button>
      </b-col>
      <!-- <b-col
        class="col-md-2"
      >
        <b-input-group
          class="input-group-merge"
        >
          <b-form-input
            v-model="searchValue"
            autocomplete="off"
            type="search"
            placeholder="Search"
          />
          <b-input-group-append
            is-text
            class="icon-append"
          >
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </b-col> -->
    </b-row>
    <div class="home-page">
      <b-table
        id="tableTickets"
        ref="table-tickets"
        refreshed
        responsive
        selectable
        noCollapse
        no-border-collapse
        show-empty
        hover
        :busy.sync="isBusy"
        :current-page="currentPage"
        :select-mode="selectMode"
        :items="getTicketsItems"
        :fields="fields"
        :class="tableTicketsScroll"
        thead-class="colorHead"
        tbody-class="colorBody"
        @row-clicked="onRowSelected"
        @filtered="onFiltered"
      >
        <template #head(ticketSourceNumber)="data">
          <span class="colorHead pr-1">{{ data.label.toUpperCase() }}</span>
          <b-img
            :class="[sort === `${data.column}.${data.field.sort.asc}` ? 'mintui_ddl_arrow_white_up_filter' : 'mintui_ddl_arrow_green_up_filter']"
            class="mintui_cursor_pointer"
            @click="sortFieldColumn(data.column, data.field.sort.asc)"
          />
          <b-img
            :class="[sort === `${data.column}.${data.field.sort.desc}` ? 'mintui_ddl_arrow_white_down_filter' : 'mintui_ddl_arrow_green_down_filter']"
            class="mintui_cursor_pointer"
            @click="sortFieldColumn(data.column, data.field.sort.desc)"
          />
        </template>
        <template #head(summary)="data">
          <span class="colorHead pr-1">{{ data.label.toUpperCase() }}</span>
        </template>
        <template #head(teisStatus)="data">
          <span class="colorHead pr-1">{{ data.label.toUpperCase() }}</span>
          <b-img
            id="teis-status-info"
            class="mintui_cursor_pointer mintui_info"
          />
          <b-tooltip
            target="teis-status-info"
            triggers="hover"
            placement="right"
            variant="light"
            customClass="teis-info-width"
            no-fade
          >
            <b-row>
              <b-col>
                <p
                  v-for="(activity, index) in activityType"
                  :key="index"
                  class="text-justify"
                >
                  <b-img
                    :class="activity.icon"
                    class="pr-1"
                  />
                  <span class="pl-1 aiops_paragraph_6f9ca8">{{ activity.name | filterDeleteUnderScore }}</span>
                </p>
              </b-col>
            </b-row>
          </b-tooltip>
        </template>
        <template #head(modifiedDate)="data">
          <span class="colorHead pr-1">{{ data.label.toUpperCase() }}</span>
          <b-img
            :class="[sort === `${data.column}.${data.field.sort.asc}` ? 'mintui_ddl_arrow_white_up_filter' : 'mintui_ddl_arrow_green_up_filter']"
            class="mintui_cursor_pointer"
            @click="sortFieldColumn(data.column, data.field.sort.asc)"
          />
          <b-img
            :class="[sort === `${data.column}.${data.field.sort.desc}` ? 'mintui_ddl_arrow_white_down_filter' : 'mintui_ddl_arrow_green_down_filter']"
            class="mintui_cursor_pointer"
            @click="sortFieldColumn(data.column, data.field.sort.desc)"
          />
        </template>
        <template #head(status)="data">
          <span class="colorHead pr-1">{{ data.label.toUpperCase() }}</span>
          <b-img
            :class="[sort === `${data.column}.${data.field.sort.asc}` ? 'mintui_ddl_arrow_white_up_filter' : 'mintui_ddl_arrow_green_up_filter']"
            class="mintui_cursor_pointer"
            @click="sortFieldColumn(data.column, data.field.sort.asc)"
          />
          <b-img
            :class="[sort === `${data.column}.${data.field.sort.desc}` ? 'mintui_ddl_arrow_white_down_filter' : 'mintui_ddl_arrow_green_down_filter']"
            class="mintui_cursor_pointer"
            @click="sortFieldColumn(data.column, data.field.sort.desc)"
          />
        </template>
        <template #head(priority)="data">
          <span class="colorHead pr-1">{{ data.label.toUpperCase() }}</span>
          <b-img
            :class="[sort === `${data.column}.${data.field.sort.asc}` ? 'mintui_ddl_arrow_white_up_filter' : 'mintui_ddl_arrow_green_up_filter']"
            class="mintui_cursor_pointer"
            @click="sortFieldColumn(data.column, data.field.sort.asc)"
          />
          <b-img
            :class="[sort === `${data.column}.${data.field.sort.desc}` ? 'mintui_ddl_arrow_white_down_filter' : 'mintui_ddl_arrow_green_down_filter']"
            class="mintui_cursor_pointer"
            @click="sortFieldColumn(data.column, data.field.sort.desc)"
          />
        </template>
        <template #head(createdDate)="data">
          <span class="colorHead pr-1">{{ data.label.toUpperCase() }}</span>
          <b-img
            :class="[sort === `${data.column}.${data.field.sort.asc}` ? 'mintui_ddl_arrow_white_up_filter' : 'mintui_ddl_arrow_green_up_filter']"
            class="mintui_cursor_pointer"
            @click="sortFieldColumn(data.column, data.field.sort.asc)"
          />
          <b-img
            :class="[sort === `${data.column}.${data.field.sort.desc}` ? 'mintui_ddl_arrow_white_down_filter' : 'mintui_ddl_arrow_green_down_filter']"
            class="mintui_cursor_pointer"
            @click="sortFieldColumn(data.column, data.field.sort.desc)"
          />
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(ticketSourceNumber)="data">
          <a
            class="colorBody ticketId"
            :href="`${hostAccess}${data.value}`"
            target="_blank"
          >
            {{ data.value }}
          </a>
        </template>
        <template #cell(teisStatus)="data">
          <b-img
            :class="data.value | activityIcon"
            class="text-center"
          />
        </template>
        <template
          slot="row-details"
          slot-scope="data"
        >
          <b-col
            v-if="data.item.currentTicketBusy"
            class="col-md-12 text-center margin-top-24 margin-bottom-34"
          >
            <div class="text-center my-2 mintui_TicketEnrichment_Icon_Neutral_blue_mini" />
          </b-col>
          <b-col
            v-if="!data.item.currentTicketBusy && data.item.detail.length === 0"
            class="col-md-12 text-center margin-top-24 margin-bottom-34"
          >
            <span class="aiops_paragraph_6f9ca8 text-center">Something were wrong. Please, refresh the page and try again later.</span>
          </b-col>
          <b-row
            v-if="!data.item.currentTicketBusy"
            class="padding-left-16 mainContainer margin-top-24"
          >
            <b-col class="col-md-4">
              <b-row class="padding-left-4 margin-bottom-24 padding-right-4">
                <b-col
                  class="description-ticket pl-0"
                >
                  <h3 class="aiops_mineral_green_heading3">
                    Main Issue Description
                  </h3>
                  <p class="aiops_paragraph_6f9ca8">
                    {{ data.item.detail.summary | filterNullValue }}
                  </p>
                  <h3 class="aiops_mineral_green_heading3">
                    Issue Description Complement
                  </h3>
                  <p class="aiops_paragraph_6f9ca8">
                    {{ data.item.detail.description | filterNullValue }}
                  </p>
                  <p>
                    <span class="aiops_mineral_green_heading3">
                      Area
                    </span>
                    <span class="aiops_paragraph_6f9ca8">
                      {{ data.item.detail.area | filterNullValue }}
                    </span>
                  </p>
                  <!-- EDP -->
                  <h3 class="aiops_mineral_green_heading3">
                    Subcategory
                  </h3>
                  <p class="aiops_paragraph_6f9ca8">
                    {{ data.item.detail.categoryLevel2 | filterNullValue }}
                  </p>
                  <h3 class="aiops_mineral_green_heading3">
                    Assigned to
                  </h3>
                  <p class="aiops_paragraph_6f9ca8">
                    {{ data.item.detail.assignedTo | filterNullValue }}
                  </p>
                  <p>
                    <span class="aiops_mineral_green_heading3">Client </span>
                    <span class="aiops_paragraph_6f9ca8">
                      {{ data.item.detail.reportedBy | filterNullValue }}.
                    </span>
                  </p>
                  <p>
                    <span class="aiops_mineral_green_heading3">Created </span>
                    <span class="aiops_paragraph_6f9ca8">
                      {{ data.item.detail.createdDate | filterNullValue }}.
                    </span>
                  </p>
                  <p>
                    <span class="aiops_mineral_green_heading3">Updated </span>
                    <span class="aiops_paragraph_6f9ca8">
                      {{ data.item.detail.modifiedDate | filterNullValue }}.
                    </span>
                  </p>
                  <b-button
                    v-if="data.item.detail.noXpand"
                    class="ibop-gothic-button"
                    @click="expandTicketDatail(data.item.detail)"
                  >
                    <b-img
                      class="mintui_icons8_enlarge"
                    />
                    <span class="align-middle aiops_label_14_3e7282">Expand Ticket</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="col-md-8">
              <b-col
                v-if="data.item.detail.loadingPrediction"
                class="col-md-12 text-center margin-top-24 margin-bottom-34 padding-top-16 padding-bottom-16 mintui__background-ddedee"
              >
                <div class="text-center my-2 mintui_TicketEnrichment_Icon_Neutral_blue_mini" />
              </b-col>
              <ticket-categorization
                v-if="!data.item.detail.loadingPrediction"
              >
                <template #header-categorization>
                  <b-col class="mainTitle">
                    <span class="card-logo aiops_label_white_14">CATEGORIZATION</span>
                  </b-col>
                </template>
                <template #subheader-categorization>
                  <b-col
                    class="col-md-4 title"
                    :class="canPredictClass"
                  >
                    <span class="card-logo aiops_white_small_3e7282">INITIAL</span>
                  </b-col>
                  <b-col
                    v-if="data.item.detail.canPredict"
                    class="col-md-8 titleAI"
                  >
                    <b-img
                      class="businessplatformlogo"
                      :src="iconTEIS"
                    />
                    <span class="card-logo aiops_white_small">AI SUGGESTED</span>
                  </b-col>
                </template>
                <template #suggested-categorization>
                  <b-row
                    align-h="end"
                  >
                    <b-col
                      class="aiops-suggested-classification-border col-md-4"
                      :class="canPredictClass"
                    >
                      <b-row
                        v-for="(item1, i) in data.item.detail.ticketAssistData.classification"
                        :key="i"
                        align-h="end"
                      >
                        <b-col
                          cols="12"
                          class="text-center"
                        >
                          <span
                            class="aiops_paragraph_6f9ca8 text-center"
                          >
                            {{ item1 }}
                          </span>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col
                      v-if="data.item.detail.canPredict"
                      class="col-md-8"
                    >
                      <b-row
                        v-for="(item, index) in data.item.detail.ticketAssistData.sugClassifications"
                        :key="index"
                        align-h="end"
                      >
                        <b-col
                          cols="5"
                          class="text-center pl-2"
                        >
                          <span
                            class="aiops_paragraph_6f9ca8 text-center"
                          >
                            {{ item }}
                          </span>
                          <span
                            v-if="data.item.detail.bestResolutionGruop"
                            class="aiops_paragraph_6f9ca8 text-center"
                          >
                            {{ data.item.detail.bestResolutionGruop }}
                          </span>
                        </b-col>
                        <b-col
                          cols="7"
                          class="text-right"
                        >
                          <b-button
                            v-if="data.item.detail.bottomAccepted"
                            class="ibop-mineral-green-button-mid"
                            @click="applyCategorisation(data.item.detail)"
                          >
                            <span class="aiops_label_white_14">Accept</span>
                          </b-button>
                          <!-- <b-button
                            v-if="data.item.teisStatus === 'AUTOMATIC_CATEGORY_CHANGE'"
                            disabled
                            class="ibop-mineral-green-button-mid mintui_cursor_not_allowed"
                          >
                            <span class="aiops_label_white_14">Accepted</span>
                          </b-button> -->
                          <b-button
                            class="ibop-white-button-mid"
                            @click="userFeedback(data.item.detail)"
                          >
                            <span class="align-middle aiops_label_14_3e7282">Choose another</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
                <template #header-similar-tickets>
                  <b-col
                    cols="12"
                    class="mainTitleSimilarTickets"
                  >
                    <b-img
                      class="businessplatformlogo"
                      :src="iconTEIS"
                    />
                    <span class="card-logo aiops_label_white_14">KNOWLEDGE BASE</span>
                  </b-col>
                </template>
                <template #similar-tickets>
                  <b-row
                    class="aiops-classification-comp-height-similar-ticket text-center"
                  >
                    <b-col
                      cols="12"
                    >
                      <span
                        class="aiops_label_14_3e7282"
                      >
                        {{ data.item.detail.knowledge ? data.item.detail.knowledge : 'There is no Knowledge Base.' }}
                      </span>
                    </b-col>
                  </b-row>
                </template>
              </ticket-categorization>
            </b-col>
          </b-row>
          <b-row
            v-if="!data.item.currentTicketBusy && !data.item.detail.loadingPrediction"
            class="mt-3"
          >
            <app-timeline>
              <app-timeline-item
                v-for="(activity, index) in data.item.detail.activities"
                :key="index"
                :variant="activity.ticketActivityType | activityColorStyle"
              >
                <div>
                  <b-media>
                    <template #aside>
                      <b-img
                        :class="activity.ticketActivityType | activityIcon"
                      />
                      <span class="padding-left-8 aiops_neutral_blue_heading4">{{ activity.ticketActivityType | filterDeleteUnderScore }}</span>
                      <small class="text-muted pl-1">{{ activity.ticketActivityDateTime | filterFormatDate }}</small>
                      <small class="text-muted pl-1">{{ activity.ticketActivitySubmitter }}</small>
                    </template>
                  </b-media>
                </div>
                <b-table
                  striped
                  responsive
                  :items="activity.ticketActivityParams"
                  class="mb-2"
                >
                  <template #head()="dataActivityParam">
                    <span class="colorHead">{{ dataActivityParam.label.toUpperCase() }}</span>
                  </template>
                  <template #cell()="dataActivityParam">
                    <i>{{ dataActivityParam.value | filterNullValue }}</i>
                  </template>
                </b-table>
              </app-timeline-item>
            </app-timeline>
          </b-row>
        </template>
      </b-table>
    </div>
    <b-row>
      <b-col class="d-flex pt-0 margin-bottom-34 col-md-3 col-sm-12">
        <span class="d-flex align-items-center">
          <b-form-select
            id="rowsPerpage"
            v-model="rowsLimit"
            :options="rowsPerPage"
            @change="dataTable()"
          />
        </span>
        <span class="d-flex align-items-center pl-1 aiops_label_14">rows per page / Total of</span>
        <span class="d-flex align-items-center aiops_label_14 padding-left-4">{{ totalTickets }}</span>
        <span class="d-flex align-items-center aiops_label_14 padding-left-4">tickets</span>
      </b-col>
      <b-col
        class="d-flex justify-content-end pt-0 margin-bottom-34 col-md-9 col-sm-12"
      >
        <b-pagination
          v-model="currentPage"
          :value="currentPage"
          :total-rows="rows"
          :per-page="rowsLimit"
          limit="10"
          :index="4"
          ellipsis-text="ººº"
          hide-goto-end-buttons
          :active="true"
          first-number
          last-number
          class="pagination-danger mt-2"
          aria-controls="table-tickets"
          @change="(currentPage)=>cambioPagina(currentPage)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
          <template #page="{ index, active }">
            <b
              v-if="active"
              class="opensans-semi-bold-white-14px"
            >{{ rowsLimit*(index + 1) - (rowsLimit-1) }} - {{ rowsLimit*(index + 1) }}</b>
            <b
              v-else
              class="aiops_label_14"
            >{{ rowsLimit*(index + 1) - (rowsLimit-1) }} - {{ rowsLimit*(index + 1) }}</b>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <!-- <div
      v-if="!verifyTicketsEmpty && ($ability.can('aiopsadmin') || $ability.can('aiopsmanager'))"
    >
      <b-row>
        <b-col class="d-flex justify-content-center flex-wrap margin-bottom-48 pt-0 pb-4">
          <b-button
            class="ibop-mineral-green-button"
            @click="checkManagerHome"
          >
            <b-img
              class="mintui_arrow_right_circle_light"
            />
            <span class="align-middle aiops_label_white_14">See Manager Home Page</span>
          </b-button>
        </b-col>
      </b-row>
    </div> -->
    <modal-aiops-component
      title="CLASSIFICATION CORRECTION"
      :logo="logo"
    >
      <template v-slot:body>
        <validation-observer
          ref="modelFeedback"
        >
          <b-form>
            <b-row class="py-2 px-2">
              <b-col
                md="12"
              >
                <b-row>
                  <b-col md="12">
                    <b-row class="pt-1">
                      <b-col md="12">
                        <p class="text-center">
                          <span
                            class="aiops_paragraph_6f9ca8"
                          >
                            Please help us correctly classify and reassign this ticket.
                          </span>
                          <br>
                          <span
                            class="aiops_paragraph_6f9ca8"
                          >
                            Our AI model learns from these corrections and improves over time.
                          </span>
                        </p>
                      </b-col>
                      <b-col
                        md="12"
                        class="margin-top-34"
                      >
                        <b-form-group
                          class="aiops_label_form_bold"
                        >
                          <label
                            for="area"
                            class="aiops_label_14_3e7282_bold"
                          >
                            Area
                          </label>
                          <validation-provider
                            #default="{ errors }"
                            name="area"
                            rules="required"
                          >
                            <b-form-select
                              id="area"
                              v-model="areaFeedback"
                              :options="feedBackSelectArea"
                              @change="getFeedbackCategoryL2"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <!-- EDP -->
                        <b-form-group
                          class="aiops_label_form_bold"
                        >
                          <label
                            for="subcategory"
                            class="aiops_label_14_3e7282_bold"
                          >
                            Sub-category
                          </label>
                          <validation-provider
                            #default="{ errors }"
                            name="subcategory"
                            rules="required"
                          >
                            <b-form-select
                              id="subcategory"
                              v-model="subcatFeedback"
                              :options="feedBackSelectCatL2"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          v-if="optionsGroup.length > 0"
                          class="aiops_label_form_bold"
                        >
                          <label
                            for="group"
                            class="aiops_label_14_3e7282_bold"
                          >
                            Reassignation group
                          </label>
                          <validation-provider
                            #default="{ errors }"
                            name="group"
                            rules="required"
                          >
                            <b-form-select
                              id="area"
                              v-model="bestGroup"
                              :options="optionsGroup"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- login button -->
                    <b-row
                      align-h="end"
                      class="margin-top-34"
                    >
                      <b-col
                        class="col-md-12 text-right"
                      >
                        <b-button
                          class="ibop-mineral-green-button"
                          @click="applyReject"
                        >
                          <b-img
                            class="mintui_arrow_right_circle_light"
                          />
                          <span class="align-middle aiops_label_white_14">Submit</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </modal-aiops-component>
  </div>
</template>

<script>
import {
  BTable,
  VBModal,
  VBTooltip,
  BCardBody,
  BPagination,
  BButton,
  BButtonGroup,
  BLink,
  BRow,
  BCol,
  BSpinner,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormSelect,
  BFormGroup,
  BForm,
  BImg,
  BCollapse,
  VBToggle,
  BMedia,
  BAvatar,
  BTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ticketsStatus from '@/@core/utils/aiops/ticketsStatus'
import useJwt from '@/auth/aiops/useJwt'
import utilsAiops from '@/@core/utils/aiops/utils'
import {
  filterFields,
  filterConditions,
  filterConditionsDate,
  filterConditionsStrings,
  filtersValues,
  rowsPerPage,
  fields,
  activityColor,
  activityIcon,
  activityType,
  fieldsTeisStatus,
} from '@/@core/utils/aiops/filterFields'
import store from '@/store'
import currentTicketCard from '../../../../card/card-product/current-ticket/cardFrame.vue'
import ticketCategorization from '../../../../card/card-product/ticket-categorization/ticketCategorization.vue'
import ticketAssist from '../../../../card/card-product/ticket-assist/ticketAssist.vue'
import TableBasicTickets from '../../../../table/bs-table/basic-product-table/TableBasicTickets.vue'
import PaginationAiopsIbop from '../../../../components/pagination/pagination-product/PaginationComponent.vue'
import ticketsTableStoreModule from './ticketTableStoreModule'
import ModalAiopsComponent from '../../../../components/modal/modal-product/ModalAiopsComponent.vue'

const {
  formatDate,
  formatDesc,
  getSubcategories,
  getSubcategoriesL2,
  getSubcategoriesL2ToFilter,
  loadingProccessSpinner,
  loading,
  buildQuery,
  setConfigHome,
  removeAccents,
} = utilsAiops()
const iconTEIS = require('@/assets/images/aiops/logo/TicketEnrichment_Icon_White.svg')

export default {
  components: {
    BTable,
    VueGoodTable,
    BCardBody,
    BPagination,
    BButton,
    BButtonGroup,
    BLink,
    BCollapse,
    BRow,
    BCol,
    BSpinner,
    currentTicketCard,
    ticketCategorization,
    ticketAssist,
    TableBasicTickets,
    PaginationAiopsIbop,
    ModalAiopsComponent,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BFormGroup,
    BForm,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    BTooltip,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  filters: {
    filterNullValue(val) {
      const newVal = val || '---'
      return newVal
    },
    filterValueCondition(val) {
      const valueFiltered = filtersValues.filter(item => item.value === val)
      return valueFiltered[0].text
    },
    filterValueLabels(val) {
      const labelFiltered = filterFields.filter(item => item.value === val)
      return labelFiltered[0].text
    },
    activityColorStyle(val) {
      return activityColor[val]
    },
    activityIcon(val) {
      return activityIcon[val]
    },
    filterDeleteUnderScore(val) {
      const newVal = val ? val.replaceAll('_', ' ') : val
      return newVal
    },
    filterFormatDate(fullDate) {
      const date = new Date(fullDate)
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      const year = date.getFullYear()
      const hour = date.toLocaleTimeString('it-IT')
      const createdDate = `${day}/${month}/${year}`

      return `${createdDate} ${hour}`
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      logo: require('@/assets/images/aiops/logo/business_platform_logo_mini.svg'),
      dataConfig: [],
      ticketsStatus,
      selectedStatus: null,
      required,
      scrollPositionTable: 0,
      loadClassification: false,
      iconTEIS,
      isBusy: false,
      currentTicketBusy: false,
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      llamada: [],
      ticket: {},
      // Note 'isActive' is left out and will not appear in the rendered table
      fields,
      items: [],
      ticketAssistData: {},
      tickets: [],
      ticketsGraph: [],
      highPredictionCategory: {},
      highPredictionSubCategory: {},
      bestResolutionGruop: null,
      selectMode: 'single',
      canPredict: null,
      modelEnabled: [],
      recallTickets: false,
      feedBackSelectArea: [],
      feedBackSelectCatL2: [],
      optionsGroup: [],
      idTicketFeedback: null,
      areaFeedback: null,
      subcatFeedback: null,
      bestGroup: null,
      searchValue: null,
      hostAccess: null,
      filterClose: false,
      filterSummary: '',
      filterDescription: '',
      filtersTable: [
        {
          field: null,
          fieldValue: null,
          condition: null,
          children: [],
        },
      ],
      filterBreadCrumb: [],
      filterFields,
      filterConditions,
      filterConditionsDate,
      filterConditionsStrings,
      filterOK: false,
      queryFilter: '',
      SelectAreaFilter: [],
      rowsLimit: '10',
      rowsPerPage,
      offSet: '0',
      sort: '',
      totalTickets: 0,
      activityType,
      fieldsTeisStatus,
      categoryTree: [],
    }
  },
  computed: {
    verifyTicketEmpty() {
      return this.verifyTicket()
    },
    verifyTicketsEmpty() {
      return this.verifyTickets()
    },
    getTicketsItems() {
      return this.items
    },
    canPredictClass() {
      return this.canPredict ? 'col-md-4' : 'col-md-12'
    },
    // reloadAbility() {
    //   return this.$ability.can('admin') ? '5' : '6'
    // },
    tableTicketsScroll() {
      return this.canPredict ? 'infiniteScrollHeightPredict' : 'infiniteScrollHeightNoPredict'
    },
    rows() {
      return this.totalRows
    },
    arrowFilter() {
      return this.filterClose ? 'mintui_ddl_arrow_green_up' : 'mintui_ddl_arrow_green_down'
    },
  },
  created() {
    const TICKETS_TABLE_STORE_MODULE_NAME = 'aiops-tickets-table'

    // Register module
    if (!store.hasModule(TICKETS_TABLE_STORE_MODULE_NAME)) store.registerModule(TICKETS_TABLE_STORE_MODULE_NAME, ticketsTableStoreModule)
    // this.getConfigHome()
    this.getCategoriesTree()
    this.getConfigList()
  },
  methods: {
    getConfigHome() {
      this.rowsLimit = localStorage.getItem('rowsLimit')
      this.rowsLimit = this.rowsLimit ? this.rowsLimit : '10'
      this.offSet = localStorage.getItem('offSet')
      this.offSet = Number.isNaN(this.offSet) ? '0' : this.offSet
      this.queryFilter = localStorage.getItem('queryFilter')
      this.queryFilter = this.queryFilter ? this.queryFilter : ''
      this.sort = localStorage.getItem('sort')
      this.sort = this.sort ? this.sort : 'modifiedDate.desc'
      this.filterOK = localStorage.getItem('filterOK')
      this.filterOK = this.filterOK ? this.filterOK : false
      this.filtersTable = JSON.parse(localStorage.getItem('filtersTable'))
      this.filtersTable = this.filtersTable ? this.filtersTable : [
        {
          field: null,
          fieldValue: null,
          condition: null,
          children: [],
        },
      ]
      this.filterBreadCrumb = JSON.parse(localStorage.getItem('filterBreadCrumb'))
      this.filterBreadCrumb = this.filterBreadCrumb ? this.filterBreadCrumb : []
    },
    selectFilterFrom(i, all = null) {
      this.queryFilter = ''
      if (all) {
        this.filtersTable = []
        if (this.filtersTable.length === 0) {
          this.addAndFilter()
        }
        this.filterOK = false
        this.filterBreadCrumb = []
        this.queryFilter = ''
        this.dataTable(1)
        return
      }
      let queryFilter = ''
      this.filtersTable.splice((i + 1), this.filtersTable.length - (i + 1))
      this.filterBreadCrumb.splice((i + 1), this.filterBreadCrumb.length - (i + 1))
      this.filterBreadCrumb.forEach((item, index) => {
        if (index !== 0) {
          const stringQuery = '_AND_'
          queryFilter = queryFilter.concat(stringQuery)
          queryFilter = buildQuery(queryFilter, item)
          return
        }
        queryFilter = buildQuery(this.queryFilter, item)
      })
      this.queryFilter = queryFilter
      this.dataTable(1)
    },
    setFilterClose() {
      this.filterClose = !this.filterClose
    },
    setValueNull(index) {
      this.filtersTable[index].fieldValue = null
      this.filtersTable[index].condition = null
    },
    addAndFilter() {
      const andFilter = {
        field: null,
        fieldValue: null,
        condition: null,
        children: [],
      }
      this.filtersTable.push(andFilter)
    },
    addOrFilter(index) {
      const orFilter = {
        field: null,
        fieldValue: null,
        condition: null,
      }
      this.filtersTable[index].children.push(orFilter)
    },
    deleteAndFilter(index) {
      this.filtersTable.splice(index, 1)
      if (this.filtersTable.length === 0) {
        this.addAndFilter()
      }
    },
    deleteOrFilter(index, i) {
      this.filtersTable[index].children.splice(i, 1)
    },
    clearAll() {
      this.filtersTable = []
      this.queryFilter = ''
      if (this.filtersTable.length === 0) {
        this.addAndFilter()
      }
      this.dataTable(1)
      this.filterOK = false
    },
    filterTable(filtersTable) {
      this.$refs.filteringModel.validate().then(success => {
        if (success) {
          this.queryFilter = ''
          // eslint-disable-next-line no-undef
          this.filterBreadCrumb = structuredClone(filtersTable)
          this.filterBreadCrumb.forEach((item, i) => {
            if (i !== 0) {
              const stringQuery = '_AND_'
              this.queryFilter = this.queryFilter.concat(stringQuery)
              this.queryFilter = buildQuery(this.queryFilter, item)
              return
            }
            this.queryFilter = buildQuery(this.queryFilter, item)
          })
          if (this.filtersTable.length === 0) {
            this.addAndFilter()
          }
          this.dataTable(1)
          this.filterOK = true
          this.filterClose = !this.filterClose
        } else {
          this.errorMessage('The fields are required')
        }
      })
    },
    getCategoriesTree() {
      store.dispatch('aiops-tickets-table/dataCategoriesTree')
        .then(response => {
          const selectCategory = response.map(cat => ({ value: cat.value, text: cat.label }))
          const categorySelector = [
            { value: null, text: 'Select Category' },
            ...selectCategory,
          ]
          this.categoryTree = response
          sessionStorage.setItem('categoryTree', JSON.stringify(this.categoryTree))
          this.feedBackSelectArea = categorySelector
          this.selectAreaFilter = categorySelector
          sessionStorage.setItem('areaList', JSON.stringify(this.selectAreaFilter))
          this.selectCatL2 = getSubcategoriesL2ToFilter(this.categoryTree)
          // this.getAreaCategorization()
          this.getConfigHome()
        })
        .catch(() => {
          loadingProccessSpinner(false)
          this.errorMessage()
        })
    },
    getAreaCategorization() {
      store.dispatch('aiops-tickets-table/dataModelTreeToFilter')
        .then(response => {
          this.model = response
          this.selectAreaFilter = getSubcategories(this.model[0])
          this.selectCatL2 = getSubcategoriesL2ToFilter(this.model[0])
          this.getConfigHome()
        })
        .catch(() => {
          loadingProccessSpinner(false)
          this.errorMessage()
        })
    },
    sortFieldColumn(field, sortType) {
      this.sort = `${field}.${sortType}`
      this.dataTable()
    },
    dataTable(value = this.currentPage) {
      this.isBusy = true
      this.currentPage = value
      this.offSet = (this.currentPage - 1) * this.rowsLimit
      this.offSet = this.offSet.toString()
      const queryParams = {
        limit: this.rowsLimit,
        offset: this.offSet,
        query: this.queryFilter,
        sort: this.sort,
      }
      setConfigHome(this.rowsLimit, this.offSet, this.queryFilter, this.sort, this.filtersTable, this.filterBreadCrumb, this.filterOK)
      let queryTickets = ''
      Object.entries(queryParams).forEach(([key, queryValue]) => {
        if (queryValue.length > 0) {
          if (queryTickets.length === 0) {
            const queryparam = `${key}=${queryValue}`
            queryTickets = queryTickets.concat(queryparam)
            return
          }
          const queryparam = `&${key}=${queryValue}`
          queryTickets = queryTickets.concat(queryparam)
        }
      })
      store.dispatch('aiops-tickets-table/dataTable', queryTickets)
        .then(response => {
          const { items, tickets, total } = response
          let ticketsList = []
          this.tickets = []
          ticketsList = items
          this.tickets = tickets
          this.items = items
          this.totalRows = total
          this.totalTickets = total
          this.perPage = ticketsList.length
          this.isBusy = false
          this.verifyTicket()
          this.verifyTickets()
          this.welcomeAssist()
          loading()
          return this.items
        })
        .catch(() => {
          this.errorMessage('Unable to obtain the list of tickets')
          this.isBusy = false
          this.welcomeAssist()
          loading()
        })
    },
    getConfigList() {
      store.dispatch('productAiops/getConfigList')
        .then(res => {
          const { data } = res
          const query = '/nav_to.do?uri=incident.do?sysparm_query=number='
          this.dataConfig = data
          const arrUrlAccess = this.dataConfig.filter(item => item.key === 'SERVICENOW_HOST')
          this.hostAccess = `${arrUrlAccess[0].value}${query}`
          const arrUser = this.dataConfig.filter(item => item.key === 'SERVICENOW_USER')
          store.dispatch('productAiops/showUser', arrUser[0].value)
          return this.getAssignmentGroup()
        })
        .catch(() => {
          this.getAssignmentGroup()
        })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    getDetailTicket(idTicket) {
      return useJwt.getTicketDetail({ id: idTicket })
        .then(response => {
          const { data } = response
          const ticketDetail = data
          ticketDetail.ticketsSimilar = []
          // ticketDetail.knowledge
          this.items = this.items.map(item => {
            const newItem = item
            if (newItem.idTicket === idTicket) {
              newItem.currentTicketBusy = false
              newItem.teisStatus = ticketDetail.teisStatus
              newItem.detail = ticketDetail
              newItem.detail.noXpand = false
              newItem.detail.loadingPrediction = true
            }
            return newItem
          })
          loadingProccessSpinner(false)
          return this.dataModelTree(ticketDetail)
        })
        .catch(() => {
          this.items = this.items.map(item => {
            const newItem = item
            if (newItem.idTicket === idTicket) {
              newItem.currentTicketBusy = false
              newItem.detail = []
              newItem.detail.noXpand = false
            }
            return newItem
          })
          loadingProccessSpinner(false)
          loading()
          return this.items
        })
    },
    getSimilarTickets(_ticketDetail, highPredictionCategory = null, highPredictionSubCategory = null) {
      const numberSimilars = 2
      const { idTicket } = _ticketDetail
      const ticketDetail = _ticketDetail
      useJwt.getSimilarTickets({ idTicket, numberSimilars })
        .then(response => {
          const { data } = response
          const ticketsSimilar = data
          ticketsSimilar.forEach((ticket, index) => {
            const item = ticket
            item.similarTicketChartOn = false
            item.currentTicketChartOn = false
            ticketsSimilar[index].similarTicketChartOn = false
            ticketsSimilar[index].currentTicketChartOn = false
            ticketsSimilar[index].similarTicketChart = {}
            ticketsSimilar[index].currentTicketChart = {}
            ticketsSimilar[index].collapseText = 'Show more'
            ticketsSimilar[index].consulted = false
            ticketsSimilar[index].createdDate = formatDate(ticketsSimilar[index].createdDate)
            ticketsSimilar[index].resolution = `Se sigue el procedimiento indicado en el artículo de conocimiento KB0000146${idTicket}`
          })
          ticketDetail.ticketsSimilar = ticketsSimilar
          this.showTicketDetail(ticketDetail, highPredictionCategory, highPredictionSubCategory)
        })
        .catch(() => {
          ticketDetail.ticketsSimilar = []
          this.showTicketDetail(ticketDetail, highPredictionCategory)
        })
    },
    dataModelTree(_ticketDatail) {
      store.dispatch('aiops-tickets-table/dataModelTree')
        .then(response => {
          this.modelEnabled = response
          // this.feedBackSelectArea = getSubcategories(this.modelEnabled)
          if (this.modelEnabled) {
            const { name } = this.modelEnabled
            this.canPredict = true
            return this.getPredictionModelCat(name, _ticketDatail)
          }
          this.canPredict = false
          this.getSimilarTickets(_ticketDatail)
          return this.canPredict
        })
        .catch(() => {
          loadingProccessSpinner(false)
          this.errorMessage()
          this.canPredict = false
          this.showTicketDetail(_ticketDatail)
          return this.canPredict
        })
    },
    getFeedbackCategoryL2() {
      this.feedBackSelectCatL2 = getSubcategoriesL2(this.categoryTree, this.areaFeedback)
      this.subcatFeedback = null
    },
    getAssignmentGroup() {
      store.dispatch('productAiops/dataAssignmentGroup')
        .then(response => {
          const data = response
          const selectData = data.map(group => ({ value: group.id, text: group.name }))
          const newAssignmentGroup = [
            { value: null, text: 'Select resolution group' },
            ...selectData,
          ]
          this.optionsGroup = newAssignmentGroup
          return this.dataTable(1)
        })
        .catch(() => {
          this.errorMessage('Unable to obtain the list of Resolution groups.')
          this.dataTable()
        })
    },
    getPredictionModelCat(modelName, _ticketDatail) {
      const { description } = _ticketDatail
      const msgToPredict = {
        instances: [description],
      }
      const modelNameFormated = removeAccents(modelName.replace(' ', '%20').replace('/', '%2F'))
      store.dispatch('aiops-tickets-table/getPredictionModel', { modelNameFormated, msgToPredict })
        .then(response => {
          const { predictions } = response
          const correctpredictionCat = []
          predictions.forEach(item => {
            Object.getOwnPropertyNames(item).forEach(val => {
              const obj1 = {
                name: val,
                // eslint-disable-next-line radix
                value: parseFloat(item[val]),
              }
              correctpredictionCat.push(obj1)
            })
          })
          const highPredictionCategory = correctpredictionCat.reduce((prev, current) => ((prev.value > current.value) ? prev : current))
          const { value } = highPredictionCategory
          highPredictionCategory.value = (value * 100)
          const modelsSubCat = this.modelEnabled.classes.find(item => item.modelClass === highPredictionCategory.name)
          const { reassignGroup } = modelsSubCat
          this.bestResolutionGruop = reassignGroup
          const modelEnabledSubCat = modelsSubCat.childModels.find(item => item.enabled === true)
          if (modelEnabledSubCat) {
            const { name } = modelEnabledSubCat
            return this.getPredictionModelSubCat(name, msgToPredict, _ticketDatail, highPredictionCategory, modelEnabledSubCat)
          }
          return this.getSimilarTickets(_ticketDatail, highPredictionCategory)
        })
        .catch(() => {
          this.errorMessage('Unable to obtain the area prediction.')
          this.canPredict = false
          this.showTicketDetail(_ticketDatail)
        })
    },
    getPredictionModelSubCat(modelName, msgToPredict, _ticketDatail, highPredictionCategory, _modelEnabledSubCat) {
      const modelNameFormated = removeAccents(modelName.replace(' ', '%20').replace('/', '%2F'))
      store.dispatch('aiops-tickets-table/getPredictionModel', { modelNameFormated, msgToPredict })
        .then(response => {
          const { predictions } = response
          const correctpredictionSubCat = []
          predictions.forEach(item => {
            Object.getOwnPropertyNames(item).forEach(val => {
              const obj1 = {
                name: val,
                // eslint-disable-next-line radix
                value: parseFloat(item[val]),
              }
              correctpredictionSubCat.push(obj1)
            })
          })
          const highPredictionSubCategory = correctpredictionSubCat.reduce((prev, current) => ((prev.value > current.value) ? prev : current))
          const { value } = highPredictionSubCategory
          highPredictionSubCategory.value = (value * 100)
          const modelsSubLevel = _modelEnabledSubCat.classes.find(item => item.modelClass === highPredictionSubCategory.name)
          if (modelsSubLevel) {
            const { reassignGroup } = modelsSubLevel.reassignGroup
            this.bestResolutionGruop = reassignGroup || this.bestResolutionGruop
          }
          return this.getSimilarTickets(_ticketDatail, highPredictionCategory, highPredictionSubCategory)
        })
        .catch(() => {
          this.errorMessage('Unable to obtain the category prediction.')
          this.showTicketDetail(_ticketDatail)
        })
    },
    showTicketDetail(_ticketDetail, highPredictionCategory = null, highPredictionSubCategory = null) {
      // this.ticket = _ticketDetail
      const ticket = _ticketDetail
      const ticketAssistData = {}
      if (highPredictionCategory) {
        this.highPredictionCategory = highPredictionCategory
        this.highPredictionSubCategory = highPredictionSubCategory
        ticket.categorySuggestion = this.highPredictionSubCategory ? `${this.highPredictionCategory.name} ${parseFloat(this.highPredictionCategory.value).toFixed(2)}% > ${this.highPredictionSubCategory.name} ${parseFloat(this.highPredictionSubCategory.value).toFixed(2)}%` : `${this.highPredictionCategory.name} ${parseFloat(this.highPredictionCategory.value).toFixed(2)}%`
        ticket.areaSuggested = this.highPredictionCategory.name
        ticket.accuracySuggest = this.highPredictionCategory.value
        if (this.highPredictionSubCategory) {
          ticket.catSuggested = this.highPredictionSubCategory.name
          ticket.accuracyCatSuggest = this.highPredictionSubCategory.value
        }
        ticketAssistData.sugClassifications = [ticket.categorySuggestion]
      }
      ticket.createdDate = formatDate(ticket.createdDate)
      ticket.modifiedDate = formatDate(ticket.modifiedDate)
      ticket.activities.forEach(activity => {
        if (activity.ticketActivityType === 'INITIAL_STATE') {
          //* EDP
          // ticketAssistData.classification = [activity.ticketActivityParams.area ? activity.ticketActivityParams.area : '---']
          ticketAssistData.classification = [`${activity.ticketActivityParams.area ? activity.ticketActivityParams.area : '---'} > ${activity.ticketActivityParams.categoryLevel2 ? activity.ticketActivityParams.categoryLevel2 : '---'}`]
          ticketAssistData.classification.push(`${activity.ticketActivityParams.assignedTo ? activity.ticketActivityParams.assignedTo : '---'}`)
        }
      })
      if (this.modelEnabled) {
        ticket.modelEnabled = this.modelEnabled
      }
      ticket.bestResolutionGruop = this.bestResolutionGruop
      ticket.optionsGroup = this.optionsGroup
      // this.setDataModalFeedback()
      ticket.activities = ticket.activities.reverse()
      ticket.activities.forEach(activity => {
        const newActivity = activity
        const arrActivityParams = []
        arrActivityParams.push(newActivity.ticketActivityParams)
        newActivity.ticketActivityParams = arrActivityParams
        return newActivity
      })
      ticket.ticketAssistData = {}
      ticket.ticketAssistData = ticketAssistData
      ticket.canPredict = this.canPredict
      this.isBusy = false
      ticket.currentTicketBusy = false
      ticket.loadCategorization = false
      ticket.noXpand = true
      ticket.loadingPrediction = false
      this.items = this.items.map(item => {
        const newItem = item
        if (newItem.idTicket === ticket.idTicket) {
          newItem.currentTicketBusy = false
          newItem.detail = ticket
        }
        return newItem
      })
      if (ticket.areaSuggested === ticket.area && (this.highPredictionSubCategory && ticket.catSuggested === ticket.categoryLevel2) && (this.bestResolutionGruop && ticket.assignedTo === ticket.bestResolutionGruop)) {
        ticket.bottomAccepted = false
      } else if (!this.highPredictionSubCategory && (this.bestResolutionGruop && ticket.assignedTo === ticket.bestResolutionGruop) && ticket.areaSuggested === ticket.area) {
        ticket.bottomAccepted = false
      } else if (!this.highPredictionSubCategory && !this.bestResolutionGruop && ticket.areaSuggested === ticket.area) {
        ticket.bottomAccepted = false
      } else {
        ticket.bottomAccepted = true
      }
      loadingProccessSpinner(false)
      loading()
      return this.items
    },
    applyCategorisation(_detailData) {
      loadingProccessSpinner(true)
      const {
        idTicket,
        areaSuggested,
        catSuggested,
        accuracySuggest,
        accuracyCatSuggest,
        bestResolutionGruop,
      } = _detailData
      this.loadClassification = true
      let applyData = {}
      if (catSuggested) {
        applyData = {
          idTicket,
          area: areaSuggested,
          categoryLevel2: catSuggested,
          accuracyArea: accuracySuggest.toFixed(2).toString(),
          accuracyCategoryLevel2: accuracyCatSuggest.toFixed(2).toString(),
        }
      } else {
        applyData = {
          idTicket,
          area: areaSuggested,
          accuracyArea: accuracySuggest.toFixed(2).toString(),
        }
      }
      if (bestResolutionGruop) {
        applyData.reassignmentGroup = bestResolutionGruop
      }
      useJwt.applyArea(applyData)
        .then(() => {
          const variant = 'success'
          const mngApply = catSuggested ? `The area has changed to ${this.highPredictionCategory.name} with an accuracy of ${parseFloat(this.highPredictionCategory.value).toFixed(2)}% and the subcategory has changed to ${this.highPredictionSubCategory.name} with an accuracy of ${parseFloat(this.highPredictionSubCategory.value).toFixed(2)}%` : `The area has changed to ${this.highPredictionCategory.name} with an accuracy of ${parseFloat(this.highPredictionCategory.value).toFixed(2)}%.`
          this.$bvToast.toast(mngApply, {
            title: 'New categorization successfully applied',
            variant,
            solid: true,
          })
          this.loadClassification = false
          this.getDetailTicket(_detailData.idTicket)
        })
        .catch(() => {
          this.loadClassification = false
          loadingProccessSpinner(false)
          this.errorMessage()
        })
    },
    applyReject() {
      this.$refs.modelFeedback.validate().then(success => {
        if (success) {
          if (this.areaFeedback === this.currentArea && this.subcatFeedback === this.currentCategoryLevel2 && this.bestGroup === this.currentResolutionGroup) {
            const variant = 'warning'
            const mngApply = 'The selected values are equal to the current ones'
            this.$bvToast.toast(mngApply, {
              title: 'Review the fields',
              variant,
              solid: true,
            })
            return
          }
          this.exitModal()
          loadingProccessSpinner(true)
          this.loadClassification = true
          let applyData = {}
          applyData = {
            idTicket: this.idTicketFeedback,
            area: this.areaFeedback,
            categoryLevel2: this.subcatFeedback,
            reassignmentGroup: this.bestGroup,
          }
          useJwt.applyArea(applyData)
            .then(() => {
              const variant = 'success'
              const mngApply = 'The ticket was correctly classify'
              this.$bvToast.toast(mngApply, {
                title: 'New categorization successfully applied',
                variant,
                solid: true,
              })
              this.loadClassification = false
              this.getDetailTicket(this.idTicketFeedback)
            })
            .catch(() => {
              this.loadClassification = false
              this.errorMessage('We are unable to apply the suggested feedback at this time.')
              this.exitModal()
              loadingProccessSpinner(false)
            })
        } else {
          this.errorMessage('All fields are mandatory')
        }
      })
    },
    errorMessage(message = 'Something was wrong') {
      const variant = 'warning'
      this.$bvToast.toast(message, {
        title: 'Classification correction',
        variant,
        solid: true,
      })
    },
    userFeedback(_detailData) {
      this.setDataModalFeedback(_detailData)
      this.$bvModal.show('modal-aiops')
    },
    setDataModalFeedback(_detailData) {
      const {
        idTicket,
        area,
        categoryLevel2,
        areaSuggested,
        catSuggested,
        assignedTo,
        bestResolutionGruop,
      } = _detailData
      this.idTicketFeedback = idTicket
      this.feedBackSelectCatL2 = getSubcategoriesL2(this.categoryTree, areaSuggested)
      this.areaFeedback = areaSuggested || null
      this.subcatFeedback = catSuggested || null
      this.currentArea = area || null
      this.currentCategoryLevel2 = categoryLevel2 || null
      this.currentResolutionGroup = assignedTo || null
      this.bestGroup = bestResolutionGruop || null
    },
    onRowSelected(items) {
      this.selected = items

      // eslint-disable-next-line no-underscore-dangle
      if (this.selected._showDetails) {
        // eslint-disable-next-line dot-notation
        this.selected['_showDetails'] = false
      } else {
        this.$set(this.selected, '_showDetails', true)
      }
      // eslint-disable-next-line no-underscore-dangle
      if (this.selected._showDetails && this.selected) {
        this.selected.currentTicketBusy = true
        this.getDetailTicket(this.selected.idTicket)
      }
    },
    verifyTicket() {
      return Object.entries(this.ticket).length === 0
    },
    verifyTickets() {
      return Object.entries(this.items).length === 0
    },
    checkManagerHome() {
      this.$router.push({ name: 'aiops-home-data-graphics', params: { dataTickets: this.tickets } })
    },
    cambioPagina(value) {
      this.dataTable(value)
    },
    expandTicketDatail(_detailData) {
      const detailDataToExpand = _detailData
      this.$router.push({ name: 'aiops-detail-similar-ticket', params: { id: detailDataToExpand.ticketSourceNumber, ticketsDetail: detailDataToExpand } })
    },
    exitModal() {
      this.$bvModal.hide('modal-aiops')
    },
    formatDesc,
    errorRequest() {
      store.dispatch('productAiops/errorRequest')
    },
    welcomeAssist() {
      store.dispatch('productAiops/showWelcomeAssist', true)
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep {
  // .b-table-sticky-header > .table.b-table > tbody.colorBody > tr > td {
  //   padding: 0.72rem 5px !important;
  // }
  .colorHead {
    background-color: #f3f2f7;
    color: #005573;
    font-size: 0.77vw;
    font-family: "Open Sans", Helvetica, Arial, serif;
  }

  .colorBody {
    background-color: #fff;
    color: #6f9ca8;
    font-size: 0.77vw;
    font-family: "Open Sans", Helvetica, Arial, serif;
    overflow-y: scroll !important;
    padding: 0.72rem 5px !important;
  }

  // b-pagination .page-item .active .page-link {
  //   border-radius: 5rem;
  //   background-color: #12a71e !important;
  // }

  // .table tbody tr {
  //   height: 55px !important;
  // }

}

.pagination {
  ::v-deep {
    .page-item.active {
      background-color: transparent;
      border-radius: 0;
    }
    .page-item.active .page-link {
      background: #ea5455 !important;
    }
    .page-link {
      background-color: transparent !important;
      color: #6f9ca8;
      font-weight: 200;
    }
    .page-link:hover {
      background-color: transparent !important;
      color: #6f9ca8;
    }
  }
}
.aiop_suggested_items {
  flex-direction: column !important;
}

.idSimilarTicket:hover {
  color: var(--mineral-green);
}

.aiops_serviceSnow_filter__breadCrumb:hover {
  text-decoration: underline;
}

</style>
